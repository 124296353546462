import { useNavigate, useSearch } from "@tanstack/react-location";
import { produce } from "immer";
import { useCallback, useMemo } from "react";
import { z } from "zod";

import { AccountsUrlParams, accountsUrlParamsSchema } from "./accountsTypes";

const encodedUrlParams = z.object({
  p: accountsUrlParamsSchema.shape.page,
  l: accountsUrlParamsSchema.shape.limit,
  v: accountsUrlParamsSchema.shape.view,
  n: accountsUrlParamsSchema.shape.name,
  e: accountsUrlParamsSchema.shape.email,
  id: accountsUrlParamsSchema.shape.accountId,
  ts: accountsUrlParamsSchema.shape.tinStatus,
});
type EncodedUrlParams = z.infer<typeof encodedUrlParams>;

export const textSearchParamsSchema = accountsUrlParamsSchema.pick({
  name: true,
  email: true,
  accountId: true,
});
export type TextSearchParams = z.infer<typeof textSearchParamsSchema>;

export const multiSelectParamsSchema = accountsUrlParamsSchema.pick({
  tinStatus: true,
});
export type MultiSelectParams = z.infer<typeof multiSelectParamsSchema>;

const decode = (params: EncodedUrlParams): AccountsUrlParams => ({
  page: params.p,
  limit: params.l,
  view: params.v,
  name: params.n,
  email: params.e,
  accountId: params.id,
  tinStatus: params.ts,
});

const encode = (params: AccountsUrlParams): EncodedUrlParams => ({
  p: params.page,
  l: params.limit,
  v: params.view,
  n: params.name,
  e: params.email,
  id: params.accountId,
  ts: params.tinStatus,
});

export const useAccountsUrlParams = () => {
  const rawUrlParams = useSearch();
  const urlParams = useMemo(
    () => decode(encodedUrlParams.parse(rawUrlParams)),
    [rawUrlParams]
  );

  const navigate = useNavigate();

  const setUrlParams = useCallback(
    (setter: (draft: AccountsUrlParams) => void) => {
      navigate({
        search: (params) => {
          const decoded = decode(encodedUrlParams.parse(params));
          const newParams = produce(decoded, setter);
          return encode(accountsUrlParamsSchema.parse(newParams));
        },
      });
    },
    [navigate]
  );

  return { urlParams, setUrlParams };
};
