import { Button, Drawer, NumericalBadge } from "@taxbit-private/cosmic";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import { useAccountsFilterDrawerForm } from "./useAccountsFilterDrawerForm";
import { useGetHydratedAccountsFilterOptions } from "../../../../../../api/hydrated-accounts/hydratedAccountsApi";
import TinStatusFilter from "../../../../../information-reporting/eligible-users/drawer/TinStatusFilter";
import { useAccountsView } from "../../../useAccountsView";

export enum AccountsFilterDrawerTrackingIds {
  CloseButton = "AccountsFilterDrawerCloseButton",
  ApplyButton = "AccountsFilterDrawerApplyButton",
  CancelButton = "AccountsFilterDrawerCancelButton",
  ClearAllFiltersButton = "AccountsFilterDrawerClearFiltersButton",
  ToggleButton = "AccountsFilterDrawerToggleButton",
  CountBadge = "AccountsFilterDrawerCountBadge",
}

const ACCOUNTS_FILTER_DRAWER_FORM_ID = "accounts-filter-drawer-form";

const AccountsFilterDrawer: React.FC = () => {
  const { view } = useAccountsView();
  const [isOpen, setIsOpen] = useState(false);
  const {
    clearAllFormFilters,
    formMethods,
    handleSubmit,
    isFormDirty,
    hasFiltersApplied,
    resetForm,
    filterCount,
  } = useAccountsFilterDrawerForm();

  const {
    data: optionValues,
    isLoading,
    isError,
  } = useGetHydratedAccountsFilterOptions();

  const closeAndReset = () => {
    setIsOpen(false);
    resetForm();
  };

  return (
    <>
      <Button
        label="Filters"
        iconName="sliders"
        variant="button-tertiary"
        size="small"
        trackingId={AccountsFilterDrawerTrackingIds.ToggleButton}
        onClick={() => setIsOpen(true)}
        isDisabled={isLoading || isError}
        utilityElement={
          filterCount > 0 ? (
            <NumericalBadge
              trackingId={AccountsFilterDrawerTrackingIds.CountBadge}
              value={filterCount}
            />
          ) : undefined
        }
      />
      <Drawer
        onClose={closeAndReset}
        isOpen={isOpen}
        title="Filters"
        closeButtonTrackingId={AccountsFilterDrawerTrackingIds.CloseButton}
        primaryButtonProps={{
          label: "Apply",
          isDisabled: !isFormDirty,
          type: "submit",
          form: ACCOUNTS_FILTER_DRAWER_FORM_ID,
          trackingId: AccountsFilterDrawerTrackingIds.ApplyButton,
        }}
        secondaryButtonProps={{
          label: "Cancel",
          onClick: closeAndReset,
          trackingId: AccountsFilterDrawerTrackingIds.CancelButton,
        }}
      >
        <FormProvider {...formMethods}>
          <FlexForm
            id={ACCOUNTS_FILTER_DRAWER_FORM_ID}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(e);
              setIsOpen(false);
            }}
          >
            {view.mappings.tinStatus && (
              <TinStatusFilter
                optionValues={optionValues?.account?.tinStatus ?? []}
              />
            )}
            {hasFiltersApplied && (
              <Button
                onClick={clearAllFormFilters}
                label="Clear All Filters"
                variant="anchor-primary-inline"
                trackingId={
                  AccountsFilterDrawerTrackingIds.ClearAllFiltersButton
                }
              />
            )}
          </FlexForm>
        </FormProvider>
      </Drawer>
    </>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`
);

export default AccountsFilterDrawer;
