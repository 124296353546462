import { Chip, ChipGroup } from "@taxbit-private/cosmic";
import { useMemo } from "react";

import { SEARCH_CHIP_LABELS, TIN_STATUS_LABELS } from "../../accountsLabels";
import { useAccountsUrlParams } from "../../useAccountsUrlParams";

enum AccountsFilterChipsTrackingId {
  FilterChip = "accounts-filter-chip",
}

const trackingId = (key: string) =>
  `${key}-${AccountsFilterChipsTrackingId.FilterChip}`;

const ariaLabel = (key: string, value: string) =>
  `Remove ${key} filter for ${value}`;

const AccountsFilterChips: React.FC = () => {
  const { urlParams, setUrlParams } = useAccountsUrlParams();

  const nameChipProps = useMemo(
    () =>
      urlParams.name
        ? {
            label: `${SEARCH_CHIP_LABELS.name}: ${urlParams.name}`,
            trackingId: trackingId("name"),
            removeButtonProps: {
              "onClick": () =>
                setUrlParams((draft) => {
                  draft.name = undefined;
                }),
              "aria-label": ariaLabel("name", urlParams.name),
            },
          }
        : undefined,
    [urlParams.name, setUrlParams]
  );

  const emailChipProps = useMemo(
    () =>
      urlParams.email
        ? {
            label: `${SEARCH_CHIP_LABELS.email}: ${urlParams.email}`,
            trackingId: trackingId("email"),
            removeButtonProps: {
              "onClick": () =>
                setUrlParams((draft) => {
                  draft.email = undefined;
                }),
              "aria-label": ariaLabel("email", urlParams.email),
            },
          }
        : undefined,
    [urlParams.email, setUrlParams]
  );

  const accountIdChipProps = useMemo(
    () =>
      urlParams.accountId
        ? {
            label: `${SEARCH_CHIP_LABELS.accountId}: ${urlParams.accountId}`,
            trackingId: trackingId("accountId"),
            removeButtonProps: {
              "onClick": () =>
                setUrlParams((draft) => {
                  draft.accountId = undefined;
                }),
              "aria-label": ariaLabel("accountId", urlParams.accountId),
            },
          }
        : undefined,
    [urlParams.accountId, setUrlParams]
  );

  const tinStatusChipProps = useMemo(
    () =>
      urlParams.tinStatus &&
      urlParams.tinStatus.map((value) => {
        return {
          label: `TIN Status: ${TIN_STATUS_LABELS[value]}`,
          trackingId: trackingId(`tinStatus-${value}`),
          removeButtonProps: {
            "onClick": () =>
              setUrlParams((draft) => {
                draft.tinStatus = draft.tinStatus?.filter(
                  (status) => status !== value
                );
              }),
            "aria-label": ariaLabel("tinStatus", value),
          },
        };
      }),
    [urlParams.tinStatus, setUrlParams]
  );

  const totalFilterLength =
    [nameChipProps, emailChipProps, accountIdChipProps].filter(Boolean).length +
    (tinStatusChipProps?.length ?? 0);

  return totalFilterLength > 0 ? (
    <ChipGroup>
      {nameChipProps && <Chip {...nameChipProps} />}
      {emailChipProps && <Chip {...emailChipProps} />}
      {accountIdChipProps && <Chip {...accountIdChipProps} />}
      {tinStatusChipProps &&
        tinStatusChipProps.map((props) => (
          <Chip key={props.trackingId} {...props} />
        ))}
    </ChipGroup>
  ) : undefined;
};

export default AccountsFilterChips;
