import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  Body,
  Divider,
  Flex,
  Modal,
  NumericalRowGroup,
} from "@taxbit-private/cosmic";
import { RhfCheckbox } from "@taxbit-private/cosmic-react-hook-form";
import pluralize from "pluralize";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import useGenerateTaxFormsConfirmationModal, {
  GenerateTaxFormsConfirmationModalTrackingId,
} from "./useGenerateTaxFormsConfirmationModal";
import { FORM_GENERATION_FORM_ID } from "../../context/formGenerationModalFormTypes";

type GenerateTaxFormsConfirmationModalProps = {
  shouldIgnoreSelectedItems?: boolean;
  onClose: () => void;
  isOpen: boolean;
};

const GenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  onClose,
  isOpen,
}: GenerateTaxFormsConfirmationModalProps) => {
  const { hasDisableFormGenerationDataComparisonAccess } =
    useDashboardFeatureFlags();

  const {
    handleSubmit,
    totalEligibleAccounts,
    totalIneligibleAccounts,
    totalFilteredAccounts,
    isLoading,
    hasFiltersApplied,
    formGenerationFormMethods,
  } = useGenerateTaxFormsConfirmationModal({
    shouldIgnoreSelectedItems,
    onClose,
  });

  const totalRows = useMemo(
    () => [
      {
        label: "Total Eligible Accounts",
        value: totalEligibleAccounts,
        key: "totalEligible",
        variant: "bold",
      } as const,
      {
        label: "Total Ineligible Accounts",
        value: totalIneligibleAccounts,
        key: "totalIneligible",
        variant: "bold",
      } as const,
    ],
    [totalIneligibleAccounts, totalEligibleAccounts]
  );

  const shouldShowCheckboxes =
    hasDisableFormGenerationDataComparisonAccess ||
    (shouldIgnoreSelectedItems && hasFiltersApplied);

  return (
    <Modal
      title="Generate Forms"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        GenerateTaxFormsConfirmationModalTrackingId.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        form: FORM_GENERATION_FORM_ID,
        type: "submit",
        label: "Generate Forms",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.SubmitButton,
        isLoading,
        loadingText: "Processing",
        variant: "button-primary",
      }}
    >
      <FormProvider {...formGenerationFormMethods}>
        <form id={FORM_GENERATION_FORM_ID} onSubmit={handleSubmit}>
          <Flex direction="column" gap="m">
            <Body>
              By clicking &quot;Generate Forms&quot; below, a form will be
              generated for all selected eligible accounts with complete account
              data, as well as any ineligible accounts that require a Void form.
            </Body>
            <Body>
              A form will not be generated for the following:
              <ul>
                <li>
                  Eligible accounts with an existing form, for which data has
                  not changed
                </li>
                <li>Eligible accounts with incomplete account data</li>
                <li>Eligible accounts that have been removed from scope</li>
                <li>
                  Ineligible accounts that do not have a previously filed form
                </li>
              </ul>
            </Body>
            {shouldShowCheckboxes && (
              <>
                <Divider />
                {shouldIgnoreSelectedItems && hasFiltersApplied && (
                  <RhfCheckbox
                    name="shouldIncludeFilters"
                    label={`Generate forms for filtered population only (${totalFilteredAccounts} ${pluralize(
                      "account",
                      totalFilteredAccounts
                    )})`}
                    trackingId={
                      GenerateTaxFormsConfirmationModalTrackingId.FilteredPopulationCheckbox
                    }
                  />
                )}
                {hasDisableFormGenerationDataComparisonAccess && (
                  <RhfCheckbox
                    name="shouldSkipCompareData"
                    label="Generate forms without data comparison"
                    trackingId={
                      GenerateTaxFormsConfirmationModalTrackingId.DataComparisonCheckbox
                    }
                  />
                )}
              </>
            )}
            <Divider />
            <NumericalRowGroup rows={totalRows} />
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default GenerateTaxFormsConfirmationModal;
