import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  DashboardQueryKey,
  POLLING_REFETCH_INTERVAL,
  createQueryMetaObject,
  isDefined,
  isTestEnv,
  logError,
  sleep,
  unwrapPublicApiWrappedQuery,
  useDashboardFeatureFlags,
  useInvalidateQueries,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  DownloadIrFormsRequest,
  EligibilityDataItem,
  IrFormKeys,
} from "@taxbit-dashboard/rest";
import { Uuid } from "@taxbit-private/uuids";
import { Optional } from "ts-toolbelt/out/Object/Optional";

import getFormsParams, { FormsParams } from "./getFormsParams";
import getEligibilityParams, {
  EligibilityDataParams,
} from "../eligible-users/getEligibilityParams";
import { IrFormTypeDateParams } from "../irApiTypes";

export const FORMS_DATA_MAX_LIMIT = 10_000;
export const FORMS_REFRESH_DELAY = isTestEnv() ? 100 : 3_500;

type GenerateTaxFormsMutationParams = {
  shouldIncludeFilters: boolean;
  shouldCompareData: boolean;
  items?: EligibilityDataItem[];
};

type RescindMutationResponse = {
  errorCount: number;
  totalCount: number;
};

export const useGetFormsAggregates = ({
  formDate,
  formType,
}: Optional<IrFormTypeDateParams>) => {
  const restSdk = useTaxBitRest();
  const apiParams =
    isDefined(formDate) && isDefined(formType)
      ? {
          "filters[document_date]": formDate,
          "filters[document_type]": formType,
        }
      : undefined;

  const query = useQuery(
    [DashboardQueryKey.IrFormsAggregates, { ...apiParams }],
    () => apiParams && restSdk.irForms.aggregates.get(apiParams),
    {
      ...createQueryMetaObject(restSdk.irForms.aggregates.buildPath()),
      enabled: isDefined(apiParams),
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetForms = ({
  formDate,
  formType,
  ...restParams
}: Omit<FormsParams, keyof IrFormTypeDateParams> &
  Optional<IrFormTypeDateParams>) => {
  const restSdk = useTaxBitRest();
  const apiParams =
    isDefined(formDate) && isDefined(formType)
      ? getFormsParams({ formDate, formType, ...restParams })
      : undefined;

  const query = useQuery(
    [DashboardQueryKey.IrForms, { ...apiParams }],
    () => apiParams && restSdk.irForms.get(apiParams),
    {
      ...createQueryMetaObject(restSdk.irForms.buildPath()),
      keepPreviousData: true,
      enabled: isDefined(apiParams),
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateTaxForms = ({
  formType,
  formDate,
  ...params
}: EligibilityDataParams) => {
  const restSdk = useTaxBitRest();
  const { shouldForm1099BIncludeCostBasis } = useDashboardFeatureFlags();

  return useMutation(
    ({
      shouldIncludeFilters,
      items,
      shouldCompareData,
    }: GenerateTaxFormsMutationParams) => {
      const requestData = (() => {
        const data = { accountIds: items?.map(({ accountId }) => accountId) };

        if (formType === DashboardFormType.Irs1099B) {
          return {
            data: {
              ...data,
              should1099BIncludeCostBasis: shouldForm1099BIncludeCostBasis,
            },
          };
        }

        return { data };
      })();

      const {
        "page[offset]": offset,
        "page[limit]": limit,
        ...filterParams
      } = getEligibilityParams({ ...params, formType, formDate });

      return restSdk.irForms.batch.post({
        params: {
          ...(shouldIncludeFilters ? filterParams : {}),
          "filters[document_type]": formType,
          "filters[document_date]": formDate,
          "filters[should_compare_data]": shouldCompareData,
        },
        requestData,
      });
    },
    {
      ...createQueryMetaObject(restSdk.irForms.batch.buildPath()),
      mutationKey: [DashboardQueryKey.IrFormsBatch, { formType, formDate }],
    }
  );
};

export const useGetFormsExport = (exportId?: Uuid) => {
  const restSdk = useTaxBitRest();
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  const query = useQuery(
    [DashboardQueryKey.IrFormsExport, { exportId }],
    () => exportId && restSdk.irForms.export.get({ exportId }),
    {
      ...createQueryMetaObject(restSdk.irForms.export.buildPath(exportId)),
      enabled: !!exportId,
      refetchInterval: hasNotificationCenterAccess
        ? false
        : (data, queryData) =>
            data?.data.presignedUrl || queryData.state.error
              ? false
              : POLLING_REFETCH_INTERVAL,
      refetchIntervalInBackground: !hasNotificationCenterAccess,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateFormsExport = () => {
  const restSdk = useTaxBitRest();

  return useMutation(
    [DashboardQueryKey.IrFormsExport],
    ({
      formDate,
      formType,
      ...restParams
    }: Omit<FormsParams, keyof IrFormTypeDateParams> &
      Optional<IrFormTypeDateParams>) => {
      if (!isDefined(formDate) || !isDefined(formType)) {
        throw new Error("Form Date and Form Type are required");
      }

      const {
        "page[offset]": _offset,
        "page[limit]": _limit,
        ...filterParams
      } = getFormsParams({ formDate, formType, ...restParams });

      return restSdk.irForms.exports.post(filterParams);
    },
    createQueryMetaObject(restSdk.irForms.exports.buildPath())
  );
};

export const useRescindIrForms = ({
  formDate,
  formType,
  onSettled,
}: Optional<IrFormTypeDateParams> & {
  onSettled?: UseMutationOptions<
    RescindMutationResponse,
    Error,
    IrFormKeys[]
  >["onSettled"];
}) => {
  const restSdk = useTaxBitRest();
  const invalidateQueries = useInvalidateQueries();

  return useMutation<RescindMutationResponse, Error, IrFormKeys[]>(
    async (formKeys: IrFormKeys[]) => {
      try {
        if (formKeys.length === 0) {
          return { errorCount: 0, totalCount: 0 };
        }

        if (!isDefined(formDate) || !isDefined(formType)) {
          throw new Error("Form Date and Form Type are required");
        }

        const urlParams = {
          "filters[document_date]": formDate,
          "filters[document_type]": formType,
        };

        const res = await restSdk.irForms.rescind.patch({
          data: { taxForms: formKeys },
        });
        await sleep(FORMS_REFRESH_DELAY);
        // Invalidate query for the form that
        // is currently being edited
        await invalidateQueries([
          [DashboardQueryKey.IrForms, { ...urlParams }],
          [DashboardQueryKey.IrFormsAggregates, { ...urlParams }],
        ]);

        return {
          errorCount: (res.data.failed ?? []).length,
          totalCount: formKeys.length,
        };
      } catch (e) {
        logError({ message: "Failed to rescind tax forms", error: e });
        return { errorCount: formKeys.length, totalCount: formKeys.length };
      }
    },
    {
      ...createQueryMetaObject(restSdk.irForms.rescind.buildPath()),
      mutationKey: [DashboardQueryKey.IrFormsRescind, { formType, formDate }],
      onSettled,
    }
  );
};

/**
 * Hook to download IR forms. We're passing `onError` and `onSuccess` callbacks
 * here instead of `mutate` because we want to show toasts globally
 * and not just in the component where the mutation is called.
 */
export const useDownloadIrForms = ({
  onError,
  onSuccess,
}: {
  onError?: UseMutationOptions<
    void,
    Error,
    Partial<DownloadIrFormsRequest["data"]>
  >["onError"];
  onSuccess?: UseMutationOptions<
    void,
    Error,
    Partial<DownloadIrFormsRequest["data"]>
  >["onSuccess"];
}) => {
  const restSdk = useTaxBitRest();

  return useMutation<void, Error, Partial<DownloadIrFormsRequest["data"]>>(
    ({
      taxFormKeys,
      documentType,
      documentYear,
    }: Partial<DownloadIrFormsRequest["data"]>) => {
      if (!isDefined(documentYear) || !isDefined(documentType)) {
        throw new Error("Form Date and Form Type are required");
      }

      return restSdk.irForms.downloads.post({
        data: {
          documentYear,
          documentType,
          taxFormKeys,
        },
      });
    },
    {
      ...createQueryMetaObject(restSdk.irForms.downloads.buildPath()),
      onSuccess,
      onError,
    }
  );
};
