import {
  Card,
  Table,
  TrimmedBoxContent,
  Flex,
  InlineSpinner,
  getEnUsErrorEmptyStateProps,
} from "@taxbit-private/cosmic";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useDispositionTableData from "./table/useDispositionTableData";

export enum DispositionTableTrackingIds {
  NextPageLoadingSpinner = "dispositionTableNextPageLoadingSpinner",
}

export const EMPTY_STATE_SUBTITLE =
  "When your fee is paid in cryptocurrency instead of fiat, you will be able to view disposal lots in this section.";

const emptyStateProps = {
  title: "No Disposal Lots",
  subtitleElement: EMPTY_STATE_SUBTITLE,
  avatarProps: {
    variant: "secondary",
    iconName: "file",
  },
} as const;

const DispositionTable: React.FC = () => {
  const {
    isLoading,
    gainItems,
    hasNextPage,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    columns,
  } = useDispositionTableData();

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: () => {
      void fetchNextPage();
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!isError,
  });

  return (
    <Card
      title="Dispositions"
      helpIconProps={{
        trackingId: "disposition-lots-help-icon",
        tooltipProps: {
          content:
            "Details on each of the tax lots disposed of in this transaction.",
          appendTo: "parent",
          isInteractive: true,
        },
      }}
    >
      <TrimmedBoxContent trim="all">
        <Table
          columns={columns}
          rows={gainItems}
          getRowKey={(row) => row.key}
          emptyStateProps={
            isError
              ? getEnUsErrorEmptyStateProps({ entity: "gains" })
              : emptyStateProps
          }
          isLoading={isLoading}
        />
      </TrimmedBoxContent>
      {isFetchingNextPage && (
        <Flex padding="contentPadding" justifyContent="center">
          <InlineSpinner
            trackingId={DispositionTableTrackingIds.NextPageLoadingSpinner}
          />
        </Flex>
      )}
      <div ref={sentryRef} />
    </Card>
  );
};

export default DispositionTable;
