import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { useCallback, useMemo } from "react";

import { ViewType, View } from "./accountsTypes";
import { useAccountsUrlParams } from "./useAccountsUrlParams";

const VIEWS: Record<ViewType, View> = {
  us: {
    type: "us",
    hasReportingProfile: true,
    mappings: {
      id: "id",
      externalId: "externalId",
      name: "name",
      dateCreated: "dateCreated",
      email: "email",
      taxClassification: "profile.us.taxClassification",
      taxCountryCodes: "profile.us.taxCountryCodes",
      tinStatus: "profile.us.tinStatus",
      taxDocumentationType: "profile.us.taxDocumentationType",
    },
  },
  global: {
    type: "global",
    hasReportingProfile: true,
    mappings: {
      id: "id",
      externalId: "externalId",
      name: "name",
      dateCreated: "dateCreated",
      email: "email",
      taxClassification: "profile.global.taxClassification",
      taxCountryCodes: "profile.global.taxCountryCodes",
      vatStatus: "profile.global.vatStatus",
      taxDocumentationType: "profile.global.taxDocumentationType",
    },
  },
};

const DISABLED_REPORTING_PROFILE_VIEWS: Record<ViewType, View> = {
  us: {
    type: "us",
    hasReportingProfile: false,
    mappings: {
      id: "id",
      externalId: "externalId",
      name: "name",
      dateCreated: "dateCreated",
      email: "email",
      taxClassification: "taxClassification",
      taxCountryCodes: "taxCountryCodes",
      tinStatus: "tinStatus",
    },
  },
  global: {
    type: "global",
    hasReportingProfile: false,
    mappings: {
      id: "id",
      externalId: "externalId",
      name: "name",
      dateCreated: "dateCreated",
      email: "email",
      taxClassification: "taxClassification",
      taxCountryCodes: "taxCountryCodes",
      vatStatus: "vatStatus",
    },
  },
};

const getViewAndOptions = (
  viewType: ViewType,
  hasReportingProfile: boolean
) => {
  const viewOptions = hasReportingProfile
    ? VIEWS
    : DISABLED_REPORTING_PROFILE_VIEWS;
  const view = viewOptions[viewType];
  return { viewOptions, view };
};

export const useAccountsView = () => {
  const { hasReportingProfile } = useDashboardFeatureFlags();

  const {
    urlParams: { view: viewParam },
    setUrlParams,
  } = useAccountsUrlParams();

  const { viewOptions, view } = useMemo(
    () => getViewAndOptions(viewParam, hasReportingProfile),
    [hasReportingProfile, viewParam]
  );

  const viewTypes = useMemo(
    () => Object.values(viewOptions).map((v) => v.type),
    [viewOptions]
  );

  const setView = useCallback(
    (viewType: ViewType) => {
      setUrlParams((draft) => {
        draft.view = viewType;

        const { view: newView } = getViewAndOptions(
          viewType,
          hasReportingProfile
        );

        if (!newView.mappings.tinStatus && draft.tinStatus) {
          draft.tinStatus = undefined;
        }
      });
    },
    [hasReportingProfile, setUrlParams]
  );

  return { view, viewOptions, viewTypes, setView };
};
