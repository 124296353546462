import {
  useSavedScrollPosition,
  DashboardPrimaryTemplate,
} from "@taxbit-dashboard/commons";
import { Dropdown } from "@taxbit-private/cosmic";

import { VIEW_LABELS } from "./accountsLabels";
import { AccountsTable } from "./table/AccountsTable";
import { useAccountsView } from "./useAccountsView";

export enum AccountsTrackingId {
  AccountsViewDropdown = "accounts-view-dropdown",
}

const Accounts: React.FC = () => {
  const { view, viewTypes, setView } = useAccountsView();

  useSavedScrollPosition({ key: "accounts" });

  return (
    <DashboardPrimaryTemplate
      title="Accounts"
      utilityElement={
        <Dropdown
          label="Accounts View"
          trackingId={AccountsTrackingId.AccountsViewDropdown}
          isLabelHidden={true}
          options={viewTypes}
          value={view.type}
          getOptionKey={(value) => value}
          getOptionLabel={(value) => VIEW_LABELS[value]}
          onChange={(value) => setView(value)}
        />
      }
    >
      <AccountsTable />
    </DashboardPrimaryTemplate>
  );
};

export default Accounts;
