import { useSetBackHrefAndNavigate } from "@taxbit-dashboard/commons";
import { HydratedAccountsFilters } from "@taxbit-dashboard/rest/";
import {
  getEnUsErrorEmptyStateProps,
  useCosmicTable,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useMemo, useCallback } from "react";

import { useAccountsTableColumns } from "./useAccountsTableColumns";
import {
  FlattenedHydratedAccount,
  useGetHydratedAccounts,
} from "../../../../api/hydrated-accounts/hydratedAccountsApi";
import { AccountsTableRow, AccountsUrlParams, View } from "../accountsTypes";
import { useAccountsUrlParams } from "../useAccountsUrlParams";
import { useAccountsView } from "../useAccountsView";

const toFilters = (
  params: AccountsUrlParams,
  view: View
): HydratedAccountsFilters => ({
  page: params.page,
  limit: params.limit,
  account: {
    name: params.name,
    email: params.email,
    externalId: params.accountId,
    tinStatus: view.hasReportingProfile ? undefined : params.tinStatus,
  },
  profile: {
    us: {
      tinStatus: view.hasReportingProfile ? params.tinStatus : undefined,
    },
  },
});

const buildRows = (
  account: FlattenedHydratedAccount,
  view: View
): AccountsTableRow => {
  const row: AccountsTableRow = {
    id: account.id,
    externalId: account.externalId,
    dateCreated: account.dateCreated,
  };

  const entries = Object.entries(view.mappings);

  for (const [key, value] of entries) {
    if (value) {
      /**
       * We require the assertions because Object.entries obliterates the relationship that the View type establishes between the account and the row
       * However, this operation is safe
       */
      const rowKey = key as keyof AccountsTableRow;
      (row[rowKey] as unknown) = account[value];
    }
  }

  return row;
};

export const useAccountsTable = () => {
  const { urlParams } = useAccountsUrlParams();
  const { view } = useAccountsView();
  const columns = useAccountsTableColumns();

  const { data, isLoading, isError, meta } = useGetHydratedAccounts(
    toFilters(urlParams, view)
  );

  const memoizedGetRowKey = useCallback(
    (row: AccountsTableRow) => row.externalId,
    []
  );

  const rows = useMemo(
    () => data?.map((a) => buildRows(a, view)) ?? [],
    [data, view]
  );

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const setBackHrefAndNavigate = useSetBackHrefAndNavigate();

  const tableProps = useCosmicTable({
    isManualSortable: false,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    density: "compact",
    rows,
    columns,
    isLoading,
    emptyStateProps: isError
      ? getEnUsErrorEmptyStateProps({ entity: "accounts" })
      : searchEmptyStateProps,
    getRowOnClick: ({ id }) => {
      setBackHrefAndNavigate({
        to: `./${id}`,
        backHrefKey: "account-details",
        reset: ["transactions", "transaction-details"],
      });
    },
  });

  const shouldDisableControls = isLoading || isError;

  return {
    tableProps,
    shouldDisableControls,
    totalCount: meta?.page?.totalCount ?? 0,
  };
};
