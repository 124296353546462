import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo } from "react";

import { TIN_STATUS_LABELS } from "../../../accounts/new-accounts/accountsLabels";
import { AccountsFiltersFormFields } from "../../../accounts/new-accounts/table/filters/drawer/useAccountsFilterDrawerForm";

export enum TinStatusFilterTrackingIds {
  Multiselect = "irTinStatusFilterMultiselect",
}

type TinStatus = Required<AccountsFiltersFormFields>["tinStatus"][0];

type TinStatusFilterProps = {
  optionValues: TinStatus[];
};

const TinStatusFilter: React.FC<TinStatusFilterProps> = ({ optionValues }) => {
  const options = useMemo(
    () =>
      optionValues.map((value) => ({
        key: value,
        label: TIN_STATUS_LABELS[value],
      })),
    [optionValues]
  );

  return (
    <RhfMultiselect<
      TinStatus,
      Pick<AccountsFiltersFormFields, "tinStatus">,
      "tinStatus",
      (typeof options)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="tinStatus"
      options={options}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="TIN Status"
      placeholder="All"
      trackingId={TinStatusFilterTrackingIds.Multiselect}
    />
  );
};

export default TinStatusFilter;
