import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  HydratedAccount,
  HydratedAccountTaxClassification,
  HydratedAccountTinStatus,
  HydratedAccountVatStatus,
  HydratedAccountsFilters,
} from "@taxbit-dashboard/rest";

export type FlattenedHydratedAccount = Omit<
  HydratedAccount,
  "profile" | "taxCountryCode"
> & {
  "taxCountryCodes"?: string[];
  "profile.us.taxClassification"?: HydratedAccountTaxClassification;
  "profile.us.taxCountryCodes"?: string[];
  "profile.us.tinStatus"?: HydratedAccountTinStatus;
  "profile.us.taxDocumentationType"?: string;
  "profile.global.taxClassification"?: HydratedAccountTaxClassification;
  "profile.global.taxCountryCodes"?: string[];
  "profile.global.vatStatus"?: HydratedAccountVatStatus;
  "profile.global.taxDocumentationType"?: string;
};

const flatten = (account: HydratedAccount): FlattenedHydratedAccount => ({
  ...account,
  "taxCountryCodes": account.taxCountryCode
    ? [account.taxCountryCode]
    : undefined,
  "profile.us.taxClassification": account.profile?.us?.taxClassification,
  "profile.us.taxCountryCodes": account.profile?.us?.taxCountryCodes,
  "profile.us.tinStatus": account.profile?.us?.tinStatus,
  "profile.us.taxDocumentationType": account.profile?.us?.taxDocumentationType,
  "profile.global.taxClassification":
    account.profile?.global?.taxClassification,
  "profile.global.taxCountryCodes": account.profile?.global?.taxCountryCodes,
  "profile.global.vatStatus": account.profile?.global?.vatStatus,
  "profile.global.taxDocumentationType":
    account.profile?.global?.taxDocumentationType,
});

export const useGetHydratedAccounts = (params: HydratedAccountsFilters) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.HydratedAccounts, { ...params }],
    () =>
      restSdk.hydratedAccounts.search(params).then((res) => ({
        ...res,
        data: res.data.map((a) => flatten(a)),
      })),
    {
      ...createQueryMetaObject(restSdk.hydratedAccounts.buildPath()),
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetHydratedAccountsFilterOptions = () => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.HydratedAccountsFilterOptions],
    () => restSdk.hydratedAccounts.filterOptions.get(),
    {
      ...createQueryMetaObject(restSdk.hydratedAccounts.buildPath()),
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
