import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

// Fields that require validation because we have logic based on their value
export const POSSIBLE_TIN_STATUSES = [
  "PENDING",
  "FOREIGN",
  "INVALID_DATA",
  "VALID_SSN_MATCH",
  "VALID_EIN_MATCH",
  "VALID_SSN_EIN_MATCH",
  "MISMATCH",
  "TIN_NOT_ISSUED",
  "ERROR",
  "UNHANDLED",
] as const;
export const tinStatusSchema = z.enum(POSSIBLE_TIN_STATUSES).catch("UNHANDLED");
export type TinStatus = z.infer<typeof tinStatusSchema>;

export const POSSIBLE_VAT_STATUSES = [
  "PENDING",
  "VALID",
  "INVALID",
  "INSUFFICIENT_DATA",
  "NOT_REQUIRED",
  "ERROR",
  "UNHANDLED",
] as const;
export const vatStatusSchema = z.enum(POSSIBLE_VAT_STATUSES).catch("UNHANDLED");
export type VatStatus = z.infer<typeof vatStatusSchema>;

export const POSSIBLE_TAX_CLASSIFICATIONS = [
  "US_IRA_ROTH",
  "US_IRA_TRADITIONAL",
  "US_IRA_SEP",
  "US_IRA_SIMPLE",
  "INDIVIDUAL",
  "LLC_S",
  "S_CORPORATION",
  "LLC_P",
  "CORPORATION",
  "LLC_C",
  "C_CORPORATION",
  "OTHER",
  "TRUST_ESTATE",
  "PARTNERSHIP",
  "INTERNATIONAL_ORGANIZATION",
  "SOLE_PROPRIETOR",
  "SIMPLE_TRUST",
  "PRIVATE_FOUNDATION",
  "COMPLEX_TRUST",
  "ESTATE",
  "TAX_EXEMPT_ORGANIZATION",
  "FOREIGN_GOVERNMENT_CONTROLLED_ENTITY",
  "FOREIGN_GOVERNMENT_INTEGRAL_PART",
  "CENTRAL_BANK_OF_ISSUE",
  "GRANTOR_TRUST",
  "UNHANDLED",
] as const;
export const taxClassificationSchema = z
  .enum(POSSIBLE_TAX_CLASSIFICATIONS)
  .catch("UNHANDLED");
export type TaxClassification = z.infer<typeof taxClassificationSchema>;

// Hydrated account
export const usProfileSchema = z.object({
  taxClassification: taxClassificationSchema.optional(),
  taxCountryCodes: z.string().array().optional(),
  tinStatus: tinStatusSchema.optional(),
  taxDocumentationType: z.string().optional(),
});
export type UsProfile = z.infer<typeof usProfileSchema>;

export const globalProfileSchema = z.object({
  taxClassification: taxClassificationSchema.optional(),
  taxCountryCodes: z.string().array().optional(),
  vatStatus: vatStatusSchema.optional(),
  taxDocumentationType: z.string().optional(),
});

export const profileSchema = z.object({
  us: usProfileSchema.optional(),
  global: globalProfileSchema.optional(),
});
export type Profile = z.infer<typeof profileSchema>;

export const hydratedAccountSchema = z.object({
  id: uuidSchema,
  organizationId: uuidSchema,
  externalId: z.string(),
  dateCreated: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  taxClassification: taxClassificationSchema.optional(),
  taxCountryCode: z.string().optional(),
  tinStatus: tinStatusSchema.optional(),
  vatStatus: vatStatusSchema.optional(),
  profile: profileSchema.optional(),
});
export type HydratedAccount = z.infer<typeof hydratedAccountSchema>;

// Response and request

export const searchHydratedAccountsResponse = getPublicApiSuccessSchema(
  z.array(hydratedAccountSchema)
);

export const dateFilters = z.object({
  gte: z.string().optional(),
  lte: z.string().optional(),
});

export const accountFilters = z.object({
  externalId: z.string().optional(),
  name: z.string().optional(),
  email: z.string().optional(),
  tinStatus: z.array(tinStatusSchema).optional(),
  vatStatus: z.array(vatStatusSchema).optional(),
  dateCreated: dateFilters.optional(),
});

export const usProfileFilters = z.object({
  taxClassification: taxClassificationSchema.optional(),
  taxCountryCodes: z.string().array().optional(),
  tinStatus: tinStatusSchema.array().optional(),
  taxDocumentationTypes: z.string().array().optional(),
});

export const globalProfileFilters = z.object({
  taxClassification: taxClassificationSchema.optional(),
  taxCountryCodes: z.string().array().optional(),
  vatStatus: vatStatusSchema.array().optional(),
  taxDocumentationTypes: z.string().array().optional(),
});

export const profileFilters = z.object({
  us: usProfileFilters.optional(),
  global: globalProfileFilters.optional(),
});

export const hydratedAccountsFiltersSchema = z.object({
  page: z.number(),
  limit: z.number(),
  account: accountFilters.optional(),
  profile: profileFilters.optional(),
});
export type HydratedAccountsFilters = z.infer<
  typeof hydratedAccountsFiltersSchema
>;

export const hydratedAccountsFilterOptionsSchema =
  hydratedAccountsFiltersSchema.omit({
    page: true,
    limit: true,
  });
export type HydratedAccountsFilterOptions = z.infer<
  typeof hydratedAccountsFilterOptionsSchema
>;

export const getHydratedAccountsFilterOptionsResponse =
  getPublicApiSuccessSchema(hydratedAccountsFilterOptionsSchema);
export type GetHydratedAccountsFilterOptionsResponse = z.infer<
  typeof getHydratedAccountsFilterOptionsResponse
>;
