import {
  Box,
  Divider,
  Flex,
  Table,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";

import AccountsTablePagination from "./AccountsTablePagination";
import AccountsFilterChips from "./filters/AccountsFilterChips";
import AccountsTableSearchBar from "./filters/AccountsTableSearchBar";
import AccountsFilterDrawer from "./filters/drawer/AccountsFilterDrawer";
import { useAccountsTable } from "./useAccountsTable";

export enum AccountsTableTrackingId {
  TableContent = "accounts-table-content",
}

export const AccountsTable: React.FC = () => {
  const { tableProps, shouldDisableControls, totalCount } = useAccountsTable();
  return (
    <Box trackingId={AccountsTableTrackingId.TableContent}>
      <TrimmedBoxContent trim="all">
        <Flex padding="m" direction="column" gap="m">
          <Flex gap="s">
            <AccountsFilterDrawer />
            <AccountsTableSearchBar isDisabled={shouldDisableControls} />
          </Flex>
          <AccountsFilterChips />
        </Flex>
        <Divider />
        <Table {...tableProps} />
        <AccountsTablePagination
          isDisabled={shouldDisableControls}
          totalCount={totalCount}
        />
      </TrimmedBoxContent>
    </Box>
  );
};
