import { pageNumberSchema } from "@taxbit-dashboard/commons";
import {
  hydratedAccountTaxClassificationSchema,
  hydratedAccountTinStatusSchema,
  hydratedAccountVatStatusSchema,
} from "@taxbit-dashboard/rest";
import { cosmicPaginationLimitSchema } from "@taxbit-private/cosmic";
import { z } from "zod";

import { FlattenedHydratedAccount } from "../../../api/hydrated-accounts/hydratedAccountsApi";

export const accountsTableRowSchema = z.object({
  id: z.string(),
  externalId: z.string(),
  name: z.string().optional(),
  dateCreated: z.string(),
  email: z.string().optional(),
  taxCountryCodes: z.string().array().optional(),
  tinStatus: hydratedAccountTinStatusSchema.optional(),
  vatStatus: hydratedAccountVatStatusSchema.optional(),
  taxClassification: hydratedAccountTaxClassificationSchema.optional(),
  taxDocumentationType: z.string().optional(),
});
export type AccountsTableRow = z.infer<typeof accountsTableRowSchema>;

export type AccountsUrlParams = z.infer<typeof accountsUrlParamsSchema>;
export const VIEW_TYPE_OPTIONS = ["us", "global"] as const;
export const viewTypeSchema = z.enum(VIEW_TYPE_OPTIONS).catch("us");
export type ViewType = z.infer<typeof viewTypeSchema>;

export const accountsUrlParamsSchema = z.object({
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
  view: viewTypeSchema,
  name: z.string().optional(),
  email: z.string().optional(),
  accountId: z.string().optional(),
  tinStatus: hydratedAccountTinStatusSchema.array().optional(),
});

/**
 * This is a type that represents the keys of T whose values are compatible with a given type
 *
 * @example
 * type T = { a: string; b: number; c: string };
 * type U = { z: string; }
 *
 * type CompatibleKeys = CompatibleKeys<T, U['z']> // "a" | "c"
 *
 */
type CompatibleKeys<T, U> = Extract<
  keyof T,
  { [K in keyof T]: T[K] extends U ? K : never }[keyof T]
>;

/**
 * A view defines the structure of the table row and how to map a given FlattenedHydratedAccount to an AccountsTableRow.
 *
 * Each mapping defines a column of the table row that we want to display and the key of the FlattenedHydratedAccount whose value will populuate it.
 */
export type View = {
  type: ViewType;
  hasReportingProfile: boolean;
  mappings: {
    [K in keyof AccountsTableRow]: AccountsTableRow[K] extends undefined
      ? undefined
      : CompatibleKeys<FlattenedHydratedAccount, AccountsTableRow[K]>;
  };
};
