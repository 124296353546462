import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTaxBitRest,
  unwrapPublicApiWrappedQuery,
  DashboardQueryKey,
  createQueryMetaObject,
  useDashboardFeatureFlags,
  useDashboardStore,
  navigateToUrl,
  useGetAccessTokenAndUpdateAuthState,
} from "@taxbit-dashboard/commons";
import {
  camelCaseKeys,
  NotificationActionType,
  NotificationCategory,
  getCurrentWebsocketHost,
  notificationSchema,
} from "@taxbit-dashboard/rest";
import { Uuid } from "@taxbit-private/uuids";
import { useCallback, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import getNotificationsParams from "./getNotificationsParams";
import {
  NotificationsPageParams,
  NotificationsTab,
} from "./notificationsApiTypes";

const useGetNotifications = (params: NotificationsPageParams) => {
  const restSdk = useTaxBitRest();
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  return unwrapPublicApiWrappedQuery(
    useQuery(
      [DashboardQueryKey.Notifications, { ...params }],
      () => restSdk.notifications.get(getNotificationsParams(params)),
      {
        ...createQueryMetaObject(restSdk.notifications.buildPath()),
        enabled: hasNotificationCenterAccess,
      }
    )
  );
};

export const useGetNotificationsByCategory = (
  params: NotificationsPageParams
) => {
  const {
    data: allNotifications = [],
    meta: allMeta,
    isLoading: isLoadingAllNotifications,
    isError: isErrorAllNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.All,
  });

  const allCount = allMeta?.page?.totalCount ?? 0;

  const {
    data: unreadNotifications = [],
    meta: unreadMeta,
    isLoading: isLoadingUnreadNotifications,
    isError: isErrorUnreadNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.Unread,
  });

  const unreadCount = unreadMeta?.page?.totalCount ?? 0;

  const {
    data: readNotifications = [],
    meta: readMeta,
    isLoading: isLoadingReadNotifications,
    isError: isErrorReadNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.Read,
  });

  const readCount = readMeta?.page?.totalCount ?? 0;

  return {
    allNotifications,
    allCount,
    unreadNotifications,
    unreadCount,
    readNotifications,
    readCount,
    isLoading:
      isLoadingAllNotifications ||
      isLoadingUnreadNotifications ||
      isLoadingReadNotifications,
    isError:
      isErrorAllNotifications ||
      isErrorUnreadNotifications ||
      isErrorReadNotifications,
  };
};

export const useMarkAllNotificationsAsRead = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => restSdk.notifications.put(),
    onSuccess: () => {
      void queryClient.invalidateQueries([DashboardQueryKey.Notifications]);
    },
  });
};

export const useMarkNotificationAsRead = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: Uuid) =>
      restSdk.notifications.read.post(notificationId),
    onSuccess: () => {
      void queryClient.invalidateQueries([DashboardQueryKey.Notifications]);
    },
  });
};

export const useFetchNotificationDownloadUrl = (notificationId: Uuid) => {
  const restSdk = useTaxBitRest();
  const addToast = useDashboardStore((store) => store.addToast);

  const { refetch: fetchNotification, isFetching: isFetchingDownloadUrl } =
    useQuery(
      [DashboardQueryKey.Notification, { notificationId }],
      () => restSdk.notifications.notification.get(notificationId),
      {
        ...createQueryMetaObject(
          restSdk.notifications.notification.buildPath(notificationId)
        ),
        // We only want to fetch a fresh version of this notification on button click,
        // so we disable the query from running outside of the 'refetch' call.
        enabled: false,
      }
    );

  const fetchNotificationDownloadUrl = useCallback(async () => {
    const { data: notification } = unwrapPublicApiWrappedQuery(
      await fetchNotification()
    );

    const downloadAction = notification?.actions?.find(
      ({ type }) => type === NotificationActionType.Download
    );

    if (downloadAction?.actionUrl) {
      navigateToUrl(downloadAction.actionUrl);
    } else {
      addToast({
        variant: "danger",
        message: "Failed to download file. Please try again.",
        trackingId: "fetch-notification-download-url-error-toast",
      });
    }
  }, [addToast, fetchNotification]);

  return {
    fetchNotificationDownloadUrl,
    isFetchingDownloadUrl,
  };
};

const useInvalidateQueriesForNotificationCategory = () => {
  const queryClient = useQueryClient();

  const invalidateQueriesForNotificationCategory = useCallback(
    (category: NotificationCategory) => {
      switch (category) {
        case NotificationCategory.IngestionFailed:
        case NotificationCategory.IngestionValidationFailed:
        case NotificationCategory.InvalidFile:
        case NotificationCategory.FormIngestionComplete:
        case NotificationCategory.AccountIngestionComplete:
        case NotificationCategory.TransactionIngestionComplete:
        case NotificationCategory.AccountIngestionValidationComplete:
        case NotificationCategory.TransactionIngestionValidationComplete:
        case NotificationCategory.FormIngestionValidationComplete:
        case NotificationCategory.DeletionFailed:
        case NotificationCategory.DeletionValidationFailed:
        case NotificationCategory.FormDeletionComplete:
        case NotificationCategory.TransactionDeletionComplete:
        case NotificationCategory.AccountDeletionComplete:
        case NotificationCategory.TransactionDeletionValidationComplete:
        case NotificationCategory.FormDeletionValidationComplete:
        case NotificationCategory.AccountDeletionValidationComplete: {
          void queryClient.invalidateQueries([DashboardQueryKey.Files]);
          break;
        }
        case NotificationCategory.AccountsExportComplete: {
          void queryClient.invalidateQueries([
            DashboardQueryKey.AccountsExport,
          ]);
          break;
        }
        case NotificationCategory.EligibilityExportComplete: {
          void queryClient.invalidateQueries([
            DashboardQueryKey.EligibilityExport,
          ]);
          break;
        }
        case NotificationCategory.FormsExportComplete: {
          void queryClient.invalidateQueries([DashboardQueryKey.IrFormsExport]);
          break;
        }
        case NotificationCategory.ExportFailed: {
          void queryClient.invalidateQueries([
            DashboardQueryKey.AccountsExport,
          ]);
          void queryClient.invalidateQueries([
            DashboardQueryKey.EligibilityExport,
          ]);
          void queryClient.invalidateQueries([DashboardQueryKey.IrFormsExport]);
          break;
        }
        case NotificationCategory.ReportGenerationComplete:
        case NotificationCategory.ReportFailed: {
          void queryClient.invalidateQueries([DashboardQueryKey.TaxReports]);
          break;
        }
        case NotificationCategory.QaPackageGenerationComplete:
        case NotificationCategory.QaPackageGenerationFailed:
        case NotificationCategory.FormGenerationComplete:
        case NotificationCategory.FormGenerationFailed:
        case NotificationCategory.FormDownloadComplete:
        case NotificationCategory.FormDownloadFailed: {
          void queryClient.invalidateQueries([
            DashboardQueryKey.CurrentCompanyUserDownloads,
          ]);
          break;
        }
        default: {
          break;
        }
      }
      void queryClient.invalidateQueries([DashboardQueryKey.Notifications]);
    },
    [queryClient]
  );

  return {
    invalidateQueriesForNotificationCategory,
  };
};

export const useNotificationsWebSocket = () => {
  const [isError, setIsError] = useState(false);

  const getToken = useGetAccessTokenAndUpdateAuthState();

  const { invalidateQueriesForNotificationCategory } =
    useInvalidateQueriesForNotificationCategory();

  const { readyState, sendMessage } = useWebSocket(
    `wss://${getCurrentWebsocketHost()}`,
    {
      onOpen: () => {
        getToken()
          .then((token) => {
            sendMessage(
              JSON.stringify({
                action: "auth",
                jwt: token,
              })
            );
          })
          .catch(() => setIsError(true));
      },
      onError: () => {
        setIsError(true);
      },
      onMessage: (message) => {
        /**
         * To avoid having to manually join together incoming data and the current query
         * response, we just use the web socket connection to signal when we should refresh
         * the query data for notifications.
         *
         * We also invalidate queries for other pages depending on the notification category
         * as data may have changed, instead of waiting for the next poll interval.
         */

        const { category } = notificationSchema.parse(
          camelCaseKeys(
            JSON.parse(message.data as string) as Record<string, unknown>
          )
        );
        invalidateQueriesForNotificationCategory(category);
      },
      shouldReconnect: () => true,
    }
  );

  return {
    isErrorWebSocket: isError,
    isConnectingWebSocket: readyState !== ReadyState.OPEN && !isError,
  };
};
