import { zodResolver } from "@hookform/resolvers/zod";
import { hasDefinedValues } from "@taxbit-dashboard/commons";
import { useMemo, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { AccountsUrlParams } from "../../../accountsTypes";
import {
  multiSelectParamsSchema,
  useAccountsUrlParams,
} from "../../../useAccountsUrlParams";

const accountsFiltersFormFieldsSchema = multiSelectParamsSchema
  .required()
  .pick({
    tinStatus: true,
  });
export type AccountsFiltersFormFields = z.infer<
  typeof accountsFiltersFormFieldsSchema
>;

const toFormData = (params: AccountsUrlParams): AccountsFiltersFormFields => ({
  tinStatus: params.tinStatus ?? [],
});

const formDataEmptyValues: AccountsFiltersFormFields = {
  tinStatus: [],
};

export const useAccountsFilterDrawerForm = () => {
  const { urlParams, setUrlParams } = useAccountsUrlParams();

  const filtersInUrl = useMemo(() => toFormData(urlParams), [urlParams]);

  const formMethods = useForm<AccountsFiltersFormFields>({
    resolver: zodResolver(accountsFiltersFormFieldsSchema),
    defaultValues: filtersInUrl,
  });

  /**
   * React location search param updates are asynchronous, so we must manually
   * reset the form to new url params when they are updated instead of relying
   * on them being available for the initial defaults for the form.
   */
  useEffect(() => {
    formMethods.reset(toFormData(urlParams));
  }, [urlParams, formMethods]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit(({ tinStatus }) => {
        setUrlParams((draft) => {
          draft.tinStatus = tinStatus;
        });
      })(e);
    },
    [formMethods, setUrlParams]
  );

  /**
   * Clears all form filters. We must use the `setValue` form methods to individually
   * mark each state as dirty, in order to not mess with RHF's state handling.
   */
  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof AccountsFiltersFormFields;

      if (value !== formDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, formDataEmptyValues[typedKey], {
          shouldDirty: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  const filterCount = filtersInUrl.tinStatus?.length ?? 0;

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(filtersInUrl),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
    filterCount,
  };
};
